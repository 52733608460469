import common_en from '@public/locales/en/common.json';
import common_fr from '@public/locales/fr/common.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: common_en },
        fr: { translation: common_fr },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
