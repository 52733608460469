'use client';

import '@app/globals.css';

import i18n from '@i18n/i18n';
import { ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';

export default function RootLayout({ children }: { children: ReactNode }) {
    return (
        <html lang="en">
            <head>
                <meta charSet="UTF-8" />
                <meta httpEquiv="Content-Language" content="en,fr" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
                <title>Josephine Noa Bayle</title>

                <meta
                    name="description"
                    content="Explore Josephine Noa Bayle's official portfolio featuring exclusive photography projects. Includes biography and contact info."
                />
                <meta name="author" content="Hugo Pattein" />
                <meta
                    name="keywords"
                    content="Josephine Noa Bayle, photographe, photographer, photography portfolio, projets photographiques, about me, à propos, contact details, coordonnées, fashion photography, photographie de mode"
                />

                <link
                    rel="canonical"
                    href="https://www.josephinenoabayle.com/"
                />
                <meta name="robots" content="index, follow" />

                <meta property="og:site_name" content="Josephine Noa Bayle" />
                <meta property="og:title" content="Josephine Noa Bayle" />
                <meta
                    property="og:description"
                    content="Explore Josephine Noa Bayle's official portfolio featuring exclusive photography projects. Includes biography and contact info."
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://www.josephinenoabayle.com/"
                />
                <meta
                    property="og:image"
                    content="https://www.josephinenoabayle.com/preview.png"
                />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:image:type" content="image/png" />
                <meta
                    property="article:published_time"
                    content="2024-05-13T00:00:00+00:00"
                />
                <meta
                    property="article:modified_time"
                    content="2024-05-13T00:00:00+00:00"
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Josephine Noa Bayle" />
                <meta
                    name="twitter:description"
                    content="Explore Josephine Noa Bayle's official portfolio featuring exclusive photography projects. Includes biography and contact info."
                />
                <meta
                    name="twitter:image"
                    content="https://www.josephinenoabayle.com/preview.png"
                />

                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="192x192"
                    href="/android-chrome-192x192.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="512x512"
                    href="/android-chrome-512x512.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="48x48"
                    href="/favicon-48x48.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                />
                <link
                    rel="mask-icon"
                    href="/safari-pinned-tab.svg"
                    color="#5bbad5"
                />
                <link rel="manifest" href="/site.webmanifest" />

                <meta name="msapplication-TileColor" content="#000000" />
                <meta
                    name="msapplication-config"
                    content="/browserconfig.xml"
                />
            </head>
            <body>
                <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
            </body>
        </html>
    );
}
